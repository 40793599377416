import React, { useState, useEffect, useRef } from 'react';
import { FaUser, FaRegEdit, FaTrashAlt, FaPlus, FaSignOutAlt } from 'react-icons/fa';
import { FaBars } from 'react-icons/fa6';
import { BsThreeDotsVertical } from "react-icons/bs";
import { useLocation } from 'react-router-dom';
import './NavAIComp.css';
import logo from '../../assets/postcare.png';

const NavbarAIComp = ({
  chatSessions,
  onSessionSelect,
  handleEdit,
  handleDelete,
  editingSessionId,
  newSessionName,
  setNewSessionName,
  handleRename,
  onNewChat,
  onLogoutClick,
  toggleSidebar,
  isSidebarOpen,
  activeSession,
  chatName,
}) => {
  const [isOpen, setIsOpen] = useState(() =>{
    return localStorage.getItem('isSidebarOpen') === 'true' || window.innerWidth > 1200;
  });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [dropdownVisible, setDropdownVisible] = useState(null); // To track which session's dropdown is visible
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const dropdownRef = useRef(null); // Ref to detect outside clicks
  const location = useLocation();
  const { userEmail } = location.state || {};
  const [isBackgroundBlurred, setIsBackgroundBlurred] = useState(false); // New state for background blur
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);


  const handleResize = () => {
    if (window.innerWidth < 1200) {
      setIsOpen(false);  // Auto Collapse the sidebar on small screens
    } else {
      setIsOpen(true);   // Open the sidebar on large screens
    }
    setIsMobile(window.innerWidth <= 1200);
    setIsOpen(window.innerWidth > 1200);
  };

  // Handle click on the three-dot button to show the dropdown
  const handleThreeDotsClick = (event, sessionId) => {
    event.stopPropagation(); // Prevent event bubbling
    const buttonRect = event.target.getBoundingClientRect(); // Get the button's position
    setDropdownPosition({ top: buttonRect.top + window.scrollY, left: buttonRect.left + window.scrollX }); // Calculate the dropdown position
    setDropdownVisible(dropdownVisible === sessionId ? null : sessionId); // Toggle the dropdown for the clicked session
  };

  // Detect click outside the dropdown
  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownVisible(null); // Close dropdown when clicked outside
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    document.addEventListener('click', handleOutsideClick); // Add event listener for outside click

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('click', handleOutsideClick); // Cleanup event listener
    };
  }, []);

  useEffect(() => {
    localStorage.setItem('isSidebarOpen', isOpen);
  }, [isOpen]);

  useEffect(() => {

    // Function to update window height when the screen is resized
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    // Add event listener to handle screen resize
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
    toggleSidebar();
    setIsBackgroundBlurred(!isBackgroundBlurred);
  };


  // Handle saving the renamed session
  const handleRenameKeyPress = (e, sessionId) => {
    if (e.key === 'Enter' || e.type === 'blur') {
      handleRename(sessionId);
    }
  };

  return (
    <>
    {isMobile && isOpen && (
      <div className={`blur-background`} onClick={toggleNavbar}></div> // Clicking on the overlay also closes the navbar
    )}
      {/* Sidebar */}
      <div
        className={`h-screen fixed top-0 left-0 transition-transform bg-white ${isOpen ? 'w-64' : 'w-16'} ${isMobile ? 'transform -translate-x-full' : ''} ${isOpen ? 'translate-x-0' : '-translate-x-full'}`}
        style={{ backgroundColor: 'white', zIndex: '99' }}
      >
        {/* Top Bar */}
        <div className="flex justify-between items-center pl-4 pt-1 bg-blue-500">
          <div className="flex-grow text-right ">
            <button className="text-gray-600 p-2 bg-transparent hover:bg-transparent focus:outline-none" onClick={toggleNavbar}>
              <FaBars size={24} />
            </button>
          </div>
        </div>
        <hr className="w-full border-gray-200 mb-2" />
        <div className="flex-grow text-center">
          <img src={logo} alt="Logo" className="h-12 mx-auto cursor-pointer" />
        </div>
        <hr className="w-full border-gray-200 my-2" />
        
        {/* Chat history */}
        {isOpen && (
          <div className="chat-history-container flex flex-col space-y-2 mt-4 px-2 flex-grow overflow-y-auto">
            <button className="bg-blue-500 text-white px-4 py-2 rounded-lg mb-2 shadow-lg hover:bg-blue-600" onClick={onNewChat}>
              <FaPlus className="inline-block mr-2" /> New Chat
            </button>
            <hr className="w-full border-gray-200 my-2" />
            <ul>
              {chatSessions.map((session) => (
                <li key={session.id} 
                className={`chat-item-container flex justify-between items-center p-2 hover:bg-gray-100 ${
                  editingSessionId === session.id ? 'border-2 border-blue-500' : 'border-none'
                }`}>
                  {editingSessionId === session.id ? (
                    <input
                      type="text"
                      value={newSessionName}
                      onChange={(e) => setNewSessionName(e.target.value)}
                      onBlur={(e) => handleRenameKeyPress(e, session.id)}
                      onKeyPress={(e) => handleRenameKeyPress(e, session.id)}
                      className="chat-name-input border-none focus:outline-none focus:border-transparent bg-inherit"
                      autoFocus
                    />
                  ) : (
                    <span onClick={() => onSessionSelect(session.id)} className="text-gray-700 cursor-pointer">
                      {activeSession?.id === session.id && chatName ? chatName : session.name}
                    </span>
                  )}
                  
                  <div className="relative">
                    <span
                      className={`three-dot-button ${dropdownVisible === session.id ? 'active' : ''}`}
                      onClick={(e) => handleThreeDotsClick(e, session.id)}
                    >
                      <BsThreeDotsVertical />
                    </span>

                    {dropdownVisible === session.id && (
                      <div
                        ref={dropdownRef}
                        className="chat-options"
                        style={{
                          position: 'fixed',
                          zIndex: 999,
                        }}
                      >
                        <ul>
                          <li onClick={() => handleEdit(session.id)} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                            <FaRegEdit className="mr-2" /> Edit
                          </li>
                          <li onClick={() => handleDelete(session.id)} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                            <FaTrashAlt className="mr-2" /> Delete
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* User Info and Logout */}
        {isOpen && (
          <div
            className="flex flex-col w-full mt-auto"
            style={{
              position: 'absolute',
              // bottom: `${windowHeight * 0.05}px`,
              bottom: '0',
              width: '100%',
              padding: '16px',
              background: 'white',
              zIndex: '999',
            }}
          >
            <hr className="w-60 border-gray-200 my-2" />
            <div className="flex w-full items-center mb-4">
              <div className="w-1/3 flex items-center justify-center" style={{ marginLeft: '-20px', fontSize: '13px' }}>
                <FaUser className="text-gray-500 h-8 w-8" style={{ marginBottom: '4px' }} />
              </div>
              <div className="w-2/3 text-left">
                <div className="text-gray-700 font-400">{userEmail}</div>
                <div className="text-black text-sm font-bold" style={{ width: '80px', padding: '1px 5px', border: '2px solid purple', boxShadow: '0 0 1px 2px blue' }}>
                  Free Trial
                </div>
              </div>
            </div>
            <hr className="w-60 border-gray-200 mb-2" />
            <button className="w-full px-4 py-2 flex items-center justify-start text-gray-700 hover:text-gray-900" onClick={onLogoutClick}>
              <FaSignOutAlt className="mr-2" />
              Logout
            </button>
          </div>
        )}
      </div>

      {/* Vertical Sidebar and Navbar Toggle Icon for mobile */}
      {!isOpen && (
        <>
          <div className="fixed top-0 left-0 h-screen w-12 bg-blue-500 z-99"></div>
          <div className="fixed top-0 right-0 w-8 h-10 bg-blue-500 flex items-center justify-center cursor-pointer z-99" onClick={toggleNavbar}>
            <FaBars size={24} className="text-white" />
          </div>
        </>
      )}
    </>
  );
};

export default NavbarAIComp;
