import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
// import ReCAPTCHA from "react-google-recaptcha";
import postcarelogo from '../assets/postcare.png';
import { FaEye, FaEyeSlash} from 'react-icons/fa';
// import "dotenv/config";
import './css/login.css';
 
const schema = z.object({
  email: z.string().email({ message: "Invalid email address" }),
  password: z.string().min(6, { message: "Password must be at least 6 characters" }),
});
 
const Login = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm({
    resolver: zodResolver(schema),
  });
 
  const onSubmit = async(data) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASEURL}/auth/login`, data, {
        withCredentials: true
      })
      if (response.status === 200 && response.data.message === 'Logged in successfully') {
        navigate('/ai-companion', { state: { userEmail: data.email } });
      } else {
        alert('Login failed. Please try again.');
      }
    } catch (error) {
      if (error.response) {
        alert(`Login failed: ${error.response.data.message}`);
      } else {
        alert('An error occurred during logging in. Please try again.');
      }
    }
  };
 
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
 
  return (
    <div classname="login-page"
    style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'white' }
    }>
      <h2 style={{ textAlign: 'center', marginBottom: '1.5rem', color: '#2B60E6', fontWeight: 'bold', fontSize:'30px' }}>Welcome Back</h2>
      <div className="login-container"style={{
        width: '380px',
        padding: '2rem',
        backgroundColor: 'white',
        borderRadius: '10px',
        border: '1px solid #ccc',
        position: 'relative'
      }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="input-group" style={{ marginBottom: '1rem' }}>
            <input
              type="text"
              placeholder="Email Address"
              {...register('email')}
              style={{
                width: '100%',
                padding: '0.75rem',
                borderRadius: '5px',
                border: '1px solid #ccc',
                fontSize: '1rem',
              }}
            />
            {errors.email && <span style={{ color: 'red', fontSize: '0.875rem' }}>{errors.email.message}</span>}
          </div>
          <div className="input-group"style={{ marginBottom: '0.5rem', position: 'relative' }}>
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              {...register('password')}
              style={{
                width: '100%',
                padding: '0.75rem',
                borderRadius: '5px',
                border: '1px solid #ccc',
                fontSize: '1rem',
              }}
            />
            <div
              style={{
                position: 'absolute',
                right: '10px',
                top: '50%',
                transform: 'translateY(-50%)',
                cursor: 'pointer',
                // width:'15px',
                // height:'16px',
              }}
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <FaEyeSlash size={16} /> : <FaEye size={16} />}
            </div>
            {errors.password && <span style={{ color: 'red', fontSize: '0.875rem' }}>{errors.password.message}</span>}
          </div>
          <div classNmae="forot-password"style={{ textAlign: 'right', marginBottom: '1.5rem' }}>
            <Link to="/reset-password" style={{ fontSize: '0.875rem', color: '#2B60E6' }}>
                Forgot Password?</Link>
          </div>
          <div style={{ marginBottom: '1.5rem', textAlign: 'center' }}>
            {/* <ReCAPTCHA */}
              {/* sitekey={process.env.REACT_APP_SITE_KEY} */}
              {/* style={{ display: 'inline-block' }} */}
            {/* /> */}
          </div>
          <button type="submit" style={{
            width: '100%',
            padding: '0.75rem',
            backgroundColor: '#2B60E6',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            fontSize: '1rem',
            fontWeight: 'bold',
            cursor: 'pointer',
          }}>
            Login
          </button>
        </form>
        <footer>
        <div style={{ position: 'absolute', bottom: '-120px', width: '100%', textAlign: 'center' }}>
        <img className="logofooter" src={postcarelogo} alt="Postcare Logo" style={{ height: '30px', marginLeft:'90px'}} />
        <div style={{ marginTop: '10px', fontSize: '0.875rem', marginLeft:'-50px'}}>
          <a href="/terms-of-use" style={{ color: '#2B60E6', textDecoration: 'none', marginRight: '15px' }}>Terms of Use |</a>
          <a href="/privacy-policy" style={{ color: '#2B60E6', textDecoration: 'none' }}>Privacy Policy</a>
          </div>
        </div>
        </footer>
      </div>
    </div>
  );
};
 
export default Login;

