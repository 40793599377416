import React, { useState, useEffect } from 'react';
import botLogo from '../../assets/botLogo.png'; // Ensure you have the Meta logo image file in your project
import userIcon from '../../assets/userIcon.jpg'; // Ensure you have a user icon image file in your project
import { FaRegThumbsUp, FaRegThumbsDown,  } from 'react-icons/fa';
import { AiOutlineClose,  } from 'react-icons/ai';
import './responseformat.css';

const ResponseFormat = ({ message, index, onFeedback, scrollToBottom, scrolloUp, isLastMessage, }) => {
  const [like, setLike] = useState(false);
  const [dislike, setDislike] = useState(false);
  const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);
  // const [showCareTeamPopup, setShowCareTeamPopup] = useState(false);
  const [selectedFeedback, setSelectedFeedback] = useState('');
  // const [selectedCareTeamMember, setSelectedCareTeamMember] = useState('');
  // const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  // const [careConnectActive, setCareConnectActive] = useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);


  useEffect(() => {
    if (showFeedbackPopup ) {
        if (isLastMessage) {
      scrollToBottom();
    } 
}
  }, [showFeedbackPopup, isLastMessage, scrollToBottom]);

  const handleLike = () => {
    setLike(!like);
    if (dislike) setDislike(false); // If dislike was selected, deselect it
  };

  const handleDislike = () => {
    setDislike(!dislike);
    setShowFeedbackPopup(!showFeedbackPopup);
    if (like) setLike(false); // If like was selected, deselect it
  };

  const feedbackOptions = [
    'Not Relevant',
    'Incorrect Information',
    'Too Complex',
    'Poor Explanation',
    'Other'
  ];

  const handleFeedbackSelect = (reason) => {
    setFeedbackSubmitted(reason);
  };

  const handleFeedbackSubmit = (option) => {
    setSelectedFeedback(option);
    if (option !== 'Dislike') {
      setDislike(false);
    } else {
      setDislike(true);
      // onFeedback(selectedFeedback);
      // setShowFeedbackPopup(false);
      // setFeedbackSubmitted(true);
      // setSelectedFeedback('');
    }
  };
  // const isUser = index % 2 === 0; // Even index is user, odd is bot
  useEffect(() => {
    if (feedbackSubmitted) {
      const timer = setTimeout(() => {
        setFeedbackSubmitted(false);
        setShowFeedbackPopup(false);  // Close the popup if desired
      }, 3000); // 3000 milliseconds = 3 seconds
      return () => clearTimeout(timer);  // Clean up the timer on component unmount
    }
  }, [feedbackSubmitted]);

  const prettifyMessage = (message) => {
    // Step 1: Replace line breaks (\n) with <br /> for proper HTML line breaks
    let formattedMessage = message.replace(/\n+/g, '<br />').trim(); // Remove any extra \n and spaces
  
    // Step 2: Replace **text** with bold (HTML <strong>) and single *text* with bold
    formattedMessage = formattedMessage
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>') // Bold text with **
      .replace(/\*(.*?)\*/g, '<strong>$1</strong>');    // Bold text with *
  
    // Step 3: Split the formatted message by <br /> for multi-line handling
    const lines = formattedMessage.split('<br />');
  
    return lines.map((line, index) => {
      line = line.trim(); // Trim extra spaces around each line
      
      // Step 4: Handle bullet points (lines that start with *)
      if (line.startsWith('*')) {
        return (
          <li key={index} style={{ listStyleType: 'disc', marginLeft: '20px' }}>
            {line.slice(1).trim()} {/* Remove the '*' and extra spaces */}
          </li>
        );
      }
  
      // Step 5: Return other lines as paragraphs with bolded content
      return (
        <p key={index} dangerouslySetInnerHTML={{ __html: line }}>
        </p>
      );
    });
  };
  

  // const handleConnect = () => {
  //   setShowCareTeamPopup(true);
  // };

  // const handleSendCareTeam = () => {
  //   setShowCareTeamPopup(false);
  //   setShowSuccessMessage(true);
  //   setCareConnectActive(true); // Activate the care connect icon
  //   setTimeout(() => {
  //     setShowSuccessMessage(false);
  //   }, 4000); // Hides the success message after 4 seconds
  // };

  // const handleUndoSendCareTeam = () => {
  //   setShowSuccessMessage(false);
  //   setCareConnectActive(false); // Deactivate the care connect icon
  // };

  return (
    <div className="chat-wrapper">
      {/* {message.map((message, index) => ( */}
      <div className={`chat-message ${message.type}`}>
        {message.type === 'bot' && (
          <div className="message-row.bot" style={{marginLeft:'50px'}}>
            <div className="messagebot-icon-container ">
              <img src={botLogo} alt="Bot Logo" className="message-icon" style={{width: '40px', height: '40px'}} />
            </div>
            <div className="message-container bot" style={{marginTop:'-45px'}}>
            <div className="messagebot-text">
              {prettifyMessage(message.message)}
            </div>
          </div>
          </div>
        )}
        {message.type === 'user' && (
          <div className="message-row user">
            <div className="message-container user">
            <div className="messageuser-text">
              {message.message}
            </div>
            </div>
            <div className="messageuser-icon-container" >
             <img src={userIcon} alt="User Icon" className="message-icon user-icon"/>
          </div>
          </div>
        )}
      </div>
      {/* ))} */}
      {message.type === 'bot' && (
        <div className="feedback-icons">
          <FaRegThumbsUp className={`icon ${like ? 'active' : ''}`} onClick={handleLike} />
          <FaRegThumbsDown className={`icon ${dislike ? 'active' : ''}`} onClick={handleDislike} />
          {/* <FaRegUser className={`icon ${careConnectActive ? 'active' : ''}`} onClick={handleConnect} /> */}
        </div>
      )}
      {showFeedbackPopup && !feedbackSubmitted && (
  <div
    className="feedback-popup"
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid darkgrey',
      borderRadius: '10px',
      position: 'relative',
      padding: '10px',
      width: 'auto', // Auto width to wrap around the content
      maxWidth: '90%',
      margin: '0 auto',
      boxSizing: 'border-box',
    }}
  >
    <AiOutlineClose
      className="close-icon"
      onClick={() => setShowFeedbackPopup(false)}
      style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }}
    />
    <h3 style={{ textAlign: 'center' }}>Tell us more:</h3>

    <div
      className="feedback-options"
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        gap: '10px',  // Flex gap between options
        marginTop: '20px',
      }}
    >
      {feedbackOptions.map((option) => (
        <div
          key={option}
          className={`feedback-option ${selectedFeedback === option ? 'selected' : ''}`}
          onClick={() => handleFeedbackSelect(option)}
          style={{
            flex: '0 1 auto', // Ensure the text fits the block naturally
            textAlign: 'center',
            border: '1px solid darkgrey',
            cursor: 'pointer',
            borderRadius: '10px',
            padding: '10px 15px',  // Keeps text centered with minimal padding
            transition: 'border-color 0.3s ease',
            whiteSpace: 'nowrap',  // Prevents text wrapping in small blocks
            color: 'black',
          }}
          onMouseEnter={(e) => e.target.style.borderColor = 'blue'}
          onMouseLeave={(e) => e.target.style.borderColor = selectedFeedback === option ? 'blue' : 'darkgrey'}
        >
          {option}
        </div>
      ))}
    </div>
  </div>
)}

{/* Thank you message after feedback submission */}
{feedbackSubmitted && (
  <div
    className="thank-you-message"
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor:'pointer',
      width: 'auto',
      margin: '20px auto',
      textAlign: 'center',
      border: '2px solid darkgrey',
      padding: '10px 20px',
      borderRadius: '10px',
      transition: 'opacity 0.5s ease-out',
    }}
  >
    Thank you for your feedback!
  </div>
)}



  
      {/* {showCareTeamPopup && (
        <div className="care-team-popup">
          <AiOutlineClose className="close-icon" onClick={() => setShowCareTeamPopup(false)} />
          <h3>Send a message to your Care Team!</h3>
          <p>As per terms and conditions your care team member might take some time to respond back to your message. For more information check your message inbox.</p>
          <div className="care-team-options">
            <div
              className={`care-team-option ${selectedCareTeamMember === 'Elon Musk - Helios Heart Care' ? 'selected' : ''}`}
              onClick={() => setSelectedCareTeamMember('Elon Musk - Helios Heart Care')}
            >
              <div className="image-text-container">
                <img src={userIcon} alt="User Icon" className="option-icon" />
                <span>Elon Musk - Helios Heart Care</span>
              </div>
            </div>
            <div
              className={`care-team-option ${selectedCareTeamMember === 'Jeff Bezos - Melinda Asthma Care' ? 'selected' : ''}`}
              onClick={() => setSelectedCareTeamMember('Jeff Bezos - Melinda Asthma Care')}
            >
              <div className="image-text-container">
                <img src={userIcon} alt="User Icon" className="option-icon" />
                <span>Jeff Bezos - Melinda Asthma Care</span>
              </div>
            </div>
            <div
              className={`care-team-option ${selectedCareTeamMember === 'Warren Buffet - Linda Orthopedics' ? 'selected' : ''}`}
              onClick={() => setSelectedCareTeamMember('Warren Buffet - Linda Orthopedics')}
            >
              <div className="image-text-container">
                <img src={userIcon} alt="User Icon" className="option-icon" />
                <span>Warren Buffet - Linda Orthopedics</span>
              </div>
            </div>
          </div>
          <div className="care-team-buttons">
            <button className="cancel-button" onClick={() => setShowCareTeamPopup(false)}>Cancel</button>
            <button className="send-button" onClick={handleSendCareTeam}>Send</button>
          </div>
        </div>
      )}
      {showSuccessMessage && (
        <div className="success-popup">
          <AiOutlineCheck className="success-icon" />
          <span>Successfully sent to your Care Team!</span>
          <button className="undo-button" onClick={handleUndoSendCareTeam}>Undo</button>
        </div>
      )} */}
    </div>
  );
};

export default ResponseFormat;


// // --------------------------prev code
// import React, { useState, useEffect } from 'react';
// import metaLogo from '../../assets/metaLogo.png'; // Ensure you have the Meta logo image file in your project
// import userIcon from '../../assets/userIcon.png'; // Ensure you have a user icon image file in your project
// import { FaRegThumbsUp, FaRegThumbsDown, FaRegUser } from 'react-icons/fa';
// import { AiOutlineClose, AiOutlineCheck } from 'react-icons/ai';
// import './responseformat.css';

// const ResponseFormat = ({ message, onFeedback, scrollToBottom, scrollToUp, isLastMessage}) => {
//   const [like, setLike] = useState(false);
//   const [dislike, setDislike] = useState(false);
//   const [showFeedbackPopup, setShowFeedbackPopup] = useState(false);
//   const [showCareTeamPopup, setShowCareTeamPopup] = useState(false);
//   const [selectedFeedback, setSelectedFeedback] = useState('');
//   const [selectedCareTeamMember, setSelectedCareTeamMember] = useState('');
//   const [showSuccessMessage, setShowSuccessMessage] = useState(false);
//   const [careConnectActive, setCareConnectActive] = useState(false);

//   useEffect(() => {
//     if (showFeedbackPopup || showCareTeamPopup || showSuccessMessage) {
//         if (isLastMessage) {
//       scrollToBottom();
//     } else {
//         scrollToUp();
//     }
// }
//   }, [showFeedbackPopup, showCareTeamPopup, showSuccessMessage, isLastMessage, scrollToUp, scrollToBottom]);

//   const handleLike = () => {
//     setLike(!like);
//     if (dislike) setDislike(false); // If dislike was selected, deselect it
//   };

//   const handleDislike = () => {
//     setDislike(!dislike);
//     setShowFeedbackPopup(!showFeedbackPopup);
//     if (like) setLike(false); // If like was selected, deselect it
//   };

//   const handleFeedbackSubmit = () => {
//     if (selectedFeedback) {
//       onFeedback(selectedFeedback);
//       setShowFeedbackPopup(false);
//       setSelectedFeedback('');
//     }
//   };

//   const handleConnect = () => {
//     setShowCareTeamPopup(true);
//   };

//   const handleSendCareTeam = () => {
//     setShowCareTeamPopup(false);
//     setShowSuccessMessage(true);
//     setCareConnectActive(true); // Activate the care connect icon
//     setTimeout(() => {
//       setShowSuccessMessage(false);
//     }, 4000); // Hides the success message after 4 seconds
//   };

//   const handleUndoSendCareTeam = () => {
//     setShowSuccessMessage(false);
//     setCareConnectActive(false); // Deactivate the care connect icon
//   };

//   return (
//     <div>
//       <div className={`chat-message ${message.type}`}>
//         {message.type === 'bot' && (
//           <div className="message-container bot">
//             <div className="messagebot-icon-container bot-icon-container">
//               <img src={metaLogo} alt="Meta Logo" className="message-icon" />
//             </div>
//             <div className="messagebot-text">
//               {message.message}
//             </div>
//           </div>
//         )}
//         {message.type === 'user' && (
//           <div className="message-container user">
//             <div className="messageuser-text">
//               {message.message}
//             </div>
//             <img src={userIcon} alt="User Icon" className="messageuser-icon user-icon" />
//           </div>
//         )}
//       </div>
//       {message.type === 'bot' && (
//         <div className="feedback-icons">
//           <FaRegThumbsUp className={`icon ${like ? 'active' : ''}`} onClick={handleLike} />
//           <FaRegThumbsDown className={`icon ${dislike ? 'active' : ''}`} onClick={handleDislike} />
//           <FaRegUser className={`icon ${careConnectActive ? 'active' : ''}`} onClick={handleConnect} />
//         </div>
//       )}
//       {showFeedbackPopup && (
//         <div className="feedback-popup">
//           <AiOutlineClose className="close-icon" onClick={() => setShowFeedbackPopup(false)} />
//           <h3>Provide your feedback</h3>
//           <select
//             value={selectedFeedback}
//             onChange={(e) => setSelectedFeedback(e.target.value)}
//           >
//             <option value="">Select feedback</option>
//             <option value="Not Relevant">Not Relevant</option>
//             <option value="Incorrect Information">Incorrect Information</option>
//             <option value="Too Complex">Too Complex</option>
//             <option value="Other">Other</option>
//           </select>
//           <button onClick={handleFeedbackSubmit}>Submit</button>
//         </div>
//       )}
//       {showCareTeamPopup && (
//         <div className="care-team-popup">
//           <AiOutlineClose className="close-icon" onClick={() => setShowCareTeamPopup(false)} />
//           <h3>Send a message to your Care Team!</h3>
//           <p>As per terms and conditions your care team member might take some time to respond back to your message. For more information check your message inbox.</p>
//           <div className="care-team-options">
//             <div
//               className={`care-team-option ${selectedCareTeamMember === 'Elon Musk - Helios Heart Care' ? 'selected' : ''}`}
//               onClick={() => setSelectedCareTeamMember('Elon Musk - Helios Heart Care')}
//             >
//               <div className="image-text-container">
//                 <img src={userIcon} alt="User Icon" className="option-icon" />
//                 <span>Elon Musk - Helios Heart Care</span>
//               </div>
//             </div>
//             <div
//               className={`care-team-option ${selectedCareTeamMember === 'Jeff Bezos - Melinda Asthma Care' ? 'selected' : ''}`}
//               onClick={() => setSelectedCareTeamMember('Jeff Bezos - Melinda Asthma Care')}
//             >
//               <div className="image-text-container">
//                 <img src={userIcon} alt="User Icon" className="option-icon" />
//                 <span>Jeff Bezos - Melinda Asthma Care</span>
//               </div>
//             </div>
//             <div
//               className={`care-team-option ${selectedCareTeamMember === 'Warren Buffet - Linda Orthopedics' ? 'selected' : ''}`}
//               onClick={() => setSelectedCareTeamMember('Warren Buffet - Linda Orthopedics')}
//             >
//               <div className="image-text-container">
//                 <img src={userIcon} alt="User Icon" className="option-icon" />
//                 <span>Warren Buffet - Linda Orthopedics</span>
//               </div>
//             </div>
//           </div>
//           <div className="care-team-buttons">
//             <button className="cancel-button" onClick={() => setShowCareTeamPopup(false)}>Cancel</button>
//             <button className="send-button" onClick={handleSendCareTeam}>Send</button>
//           </div>
//         </div>
//       )}
//       {showSuccessMessage && (
//         <div className="success-popup">
//           <AiOutlineCheck className="success-icon" />
//           <span>Successfully sent to your Care Team!</span>
//           <button className="undo-button" onClick={handleUndoSendCareTeam}>Undo</button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ResponseFormat;