import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import postcarelogo from '../assets/postcare.png';
import axios from 'axios'; // Ensure this path is correct

const ResetNewPassword = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [emailSent, setEmailSent] = useState(false);

  const onSubmit = async (data) => {
    try {
      // Replace this URL with your actual backend endpoint
        // const data= JSON.stringify(data)
      const response = await axios.post(`${process.env.REACT_APP_BASEURL}/auth/forgotpass`, data, {
        withCredentials: true
      })
      if (response.status===201) {
        // setEmailSent(true);
        // navigate('/set-new-password');
        console.log("init")
        alert('Check mail for further details!     need to adddddd some page heree');
      } else {
        alert('Reset password failed. Please try again.');
      }
    } catch (error) {
      // console.log(error)
      alert('An error occurred during the reset process. Please try again.');
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'white' }}>
      <h2 style={{ textAlign: 'center', marginBottom: '1.5rem', color: '#2B60E6', fontWeight: 'bold', fontSize: '30px', marginTop:'-195px' }}>
        Reset Your Password
      </h2>
      <p style={{marginTop:'5px',marginBottom:'20px', color:'gray'}}>Please provide the phone number/email address that you used to log in/sign up to your account.</p>
      {/* <div style={{
        width: '380px',
        padding: '2rem',
        backgroundColor: 'white',
        borderRadius: '10px',
        border: '1px solid #ccc',
        position: 'relative'
      }}> */}
        {!emailSent ? (
          <form onSubmit={handleSubmit(onSubmit)}>
            <div style={{ marginBottom: '1rem' }}>
              <input
                type="email"
                placeholder="Enter Your Email"
                {...register('email', { required: 'Email is required' })}
                style={{
                  width: '500px',
                  padding: '0.75rem',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  fontSize: '1rem',
                }}
              />
              {errors.email && <span style={{ color: 'red', fontSize: '0.875rem' }}>{errors.email.message}</span>}
            </div>
            <button type="submit" style={{
              width: '100%',
              padding: '0.75rem',
              backgroundColor: '#2B60E6',
              color: 'white',
              border: 'none',
              borderRadius: '5px',
              fontSize: '1rem',
              fontWeight: 'bold',
              cursor: 'pointer',
              marginTop:'10px',
            }}>
              Continue
            </button>
          </form>
        ) : (
          <p style={{ color: 'green', textAlign: 'center', marginTop: '1rem' }}>
            A reset link has been sent to your email. Please check your inbox.
          </p>
        )}

        <div style={{ position: 'absolute', bottom: '-120px', width: '100%', textAlign: 'center' }}>
          <img  src={postcarelogo} alt="Postcare Logo" style={{ height: '30px', marginLeft: '90px' }} />
          <div style={{ marginTop: '10px', fontSize: '0.875rem', marginLeft: '-50px' }}>
            <a href="/terms-of-use" style={{ color: '#2B60E6', textDecoration: 'none', marginRight: '15px' }}>Terms of Use |</a>
            <a href="/privacy-policy" style={{ color: '#2B60E6', textDecoration: 'none' }}>Privacy Policy</a>
          </div>
        </div>
      </div>
    // </div>
  );
};

export default ResetNewPassword;
